import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Waymon',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: ()=> import('../views/Home.vue'),
    meta: {
      title: '卡密',
      icon: require('../assets/tabbar/home.png'),
      icon_s: require('../assets/tabbar/home_s.png'),
      showTabBar: true
    }
  },
  {
    path: '/Code',
    name: 'Code',
    component: ()=> import('../views/Code.vue'),
    meta: {
      title: '模版',
      icon: require('../assets/tabbar/my.png'),
      icon_s: require('../assets/tabbar/my_s.png'),
      showTabBar: true
    }
  },
  {
    path: '/Notice',
    name: 'Notice',
    component: ()=> import('../views/Notice.vue'),
    meta: {
      title: '通知',
      icon: require('../assets/tabbar/art.png'),
      icon_s: require('../assets/tabbar/art_s.png'),
      showTabBar: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 判断当前运行环境是否为生产环境
  if (process.env.NODE_ENV === 'production') {

  } else {

  }
  next()
})

export default router
