<template>
  <div id="app">
    <van-config-provider class="waymon_container" :theme="state.theme">
      <router-view/>
    </van-config-provider>
    <!-- tabbar -->
    <van-tabbar
        class="waymon_tabbar"
        v-model="active"
        route
        fixed
        active-color="#6B87F4"
        inactive-color="#b8f0f4"
        :safe-area-inset-bottom="bottom"
        v-show="$router.currentRoute.value.meta.showTabBar"
        @change="onChange"
    >
      <van-tabbar-item class="waymon_tabbar_item" v-for="(item,index) in router.options.routes.slice(1,4)" :key="index" :to="item.path">
        <span>{{ item.meta.title }}</span>
        <template #icon="props">
          <img :src="props.active ? item.meta.icon_s:item.meta.icon" alt=""/>
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <WaymonToast ref="waymonToast"></WaymonToast>
  </div>
</template>
<script setup>
import {ref, reactive, onMounted} from 'vue'
import router from "@/router";
import WaymonToast from "@/components/WaymonToast.vue";
const waymonToast = ref()
const active = ref(0)
const bottom = ref(true)
const state = reactive({
  theme:"light",
})
onMounted(()=>{

})
const onChange = (e) => {

}
window.onload = function() {
  document.addEventListener('touchstart', function(event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  });
  document.addEventListener('gesturestart', function(event) {
    event.preventDefault()
  })
};
</script>
<style>

</style>
